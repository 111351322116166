//================================================================================

// @include media-breakpoint-down(xs) {}
// @include media-breakpoint-down(sm) {}
// @include media-breakpoint-down(md) {}
// @include media-breakpoint-down(lg) {} XXXX以下
// @include media-breakpoint-between(md, xl) {}  特殊區間
// @include media-breakpoint-up(sm) { ... }   XXXX以上

//================================================================================
/* 主要框架設置 */
//================================================================================
html {
  height: 100% !important;
  overflow-x: hidden;
  scroll-behavior: smooth;
  @include ie-only {
    display: flex !important;
    flex-direction: column !important;
  }
}

body {
  font-family: $font-family-base;
  font-weight: $font-weight-base;
  letter-spacing: 0.065rem;
  height: 100%;
  min-height: 100%; 
  display: flex !important;
  flex-direction: column !important;
  background: url(../images/web_bg.png) repeat;

  //ie fix
  @include ie-only {
    height: auto;
    min-height: 100%;
  }
  &:not(.index) main{
    padding-top: $navbar-sst-height + 0.3125rem;
    padding-bottom: 3rem;
  }
}

//主要內容外容器
main {
  flex-shrink: 0 !important;
  position: relative;
  // 收合時避開選單高度
  @include break-down {
    padding-top: $navbar-sst-height + 0.3125rem;
  }
}

//footer
footer {
  font-size: 0.875rem;
  font-weight: $font-weight-normal;
  margin-top: auto !important;
  background-color: $main-color01;
}
.footer {
  &-copyright {
    color: $white;
    text-align: center;
    text-transform: uppercase;
    padding: 0.75rem 1rem;
    a {
      color: $white;
      &:hover {
        color: inherit;
        border-bottom: dashed 1px $gray-300;
      }
    }
    @include break-up {
      @include flex-center;
    }
  }
}

a, a * {
  transition: all .3s ease-in;
  &:hover {
    text-decoration: none;
  }
}

ol, ul, dl {
  padding-inline-start: 1.125em;
}

.card {
  &-btns {
    .btn {
      font-weight: $font-weight-medium;
      padding: 0.125rem 0.25rem;
      margin: 0.25rem 0;
    }
  }
}

//================================================================================
/* 多數整體細節 */
//================================================================================

//flex簡易容易
.flex {
  &-center {
    @include flex-center;
  }
  
  &-align-center {
    @include flex-align-center;
  }
}

//================================================================================
//背景圖片設置
//================================================================================
.background-img {
  width: 100%;
  padding: 0;
  display: block;
  background: $white no-repeat center center;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.img-21by9 {
  padding-top: 42.857% !important;
}

.img-16by9 {
  padding-top: 56.25% !important;
}

.img-4by3 {
  padding-top: 75% !important;
}

.img-1by1 {
  padding-top: 100% !important;
}

//================================================================================
//分離式Table (ゝ∀･)b
//================================================================================
table.table {
  thead th {
    font-weight: 500;
    font-size: 0.938rem;
    white-space: nowrap;
    padding: $table-cell-padding-sm $table-cell-padding;
  }

  a {
    &:not(.btn):hover {
      color: darken($main-color01, 8%);
    }
  }
}

table.data-table {
  //rwd break setting
  @include media-breakpoint-down(md) {
    border: 0;
    border-collapse: collapse;

    /* Force table to not be like tables anymore */
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    &.table-striped tbody tr:nth-of-type(even) {
      background-color: initial;
    }

    tr {
      border: $table-border-width solid $table-border-color;
      margin-bottom: 1rem;
      border-radius: $border-radius;

      &:hover {
        background-color: transparent;
      }

      &:hover,
      &.active {
        box-shadow: $box-shadow-sm;
      }
    }

    td {
      white-space: normal;
      line-height: 1.2;
      padding: 0.5rem $table-cell-padding;
      border: none;
      display: flex;
      align-items: center;

      // justify-content: space-between;
      &:nth-of-type(even) {
        background-color: darken($gray-100, 3);
      }
    }

    th,
    td {
      &[data-title]:before {
        color: $gray-700;
        font-size: 0.938rem;
        font-weight: $font-weight-medium;
        // white-space: nowrap;
        flex-basis: 30%;
        min-width: 30%;
        padding-right: 0.5rem;
        display: inline-block;
      }

      &:before {
        content: attr(data-title);
      }
    }
  }
}


//回到上方
.goTop {
  color: $gray-100;
  width: 3rem;
  height: 3rem;
  box-shadow: $box-shadow;
  background-color: rgba($black, .35);
  position: fixed;
  bottom: 4rem;
  right: 0.75rem;
  z-index: $zindex-sticky;
  display: none;
  align-items: center;
  justify-content: center;
  i {
    font-size: 2rem;
  }
  &:hover, &:focus, &:active {
    color: $white;
    background-color: $main-color01;
  }
}

