//================================================================================

// @include media-breakpoint-down(xs) {}
// @include media-breakpoint-down(sm) {}
// @include media-breakpoint-down(md) {}
// @include media-breakpoint-down(lg) {} XXXX以下
// @include media-breakpoint-between(md, xl) {}  特殊區間
// @include media-breakpoint-up(sm) { ... }   XXXX以上

//================================================================================


//================================================================================
/* 主要選單設定 */
//================================================================================

header {
  background-color: rgba($white, .95);
  border-top: solid 0.3125rem $main-color01;
  padding: 0;
  box-shadow: 0 1px 2px rgba($black, 0.16);
  position: relative;
  z-index: 2;
  @include break-up {
    background-color: rgba($white, 0.85);
  }
}

//LOGO
.navbar-brand {
  margin-bottom: 0;

  @include media-breakpoint-down(sm) {
    margin-right: 0;
  }

  a {
    @include text-hide;
    width: 140px;
    height: 64px;
    display: block;
    background: url(../images/logo.svg) no-repeat left center;
    background-size: contain;

    @include media-breakpoint-up(xl) {
      width: 188px;
    }
  }
}

//主要選單
.navbar-sst {
  font-family: $font-family-head;
  min-height: $navbar-sst-height;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: relative;

  //主要選單行動版
  @include break-down {
    padding-top: 1rem;
    padding-bottom: 1rem;
    >[class*="container"] {
      justify-content: initial;
    }
  }

  //預設選單開關
  .navbar-toggler {
    padding: 0.25rem 0.5rem;
    margin-left: auto;
    border-color: $gray-700;

    &-icon {
      width: 1.125em;
      height: 1.125em;
    }

    @include media-breakpoint-down(sm) {
      width: 40px;
      height: 32px;
      padding: 0.125rem;
    }
  }

  //主選單設定
  .menu-sst {
    padding-top: 1rem;
    padding-bottom: 1rem;
    > .nav-item {

      &:hover,
      &:active,
      &.active,
      &:focus-within {
        .nav-link {
          color: $main-color01;
        }
      }

      @include break-down {
        &:hover,
        &:active,
        &.active,
        &:focus-within {
          .nav-link {
            background-color: rgba($main-color01, 0.08);
          }
        }
      }
    }

    .nav-link {
      color: $text-color;
      text-align: center;
      font-size: 1.125rem;
      font-weight: $font-weight-bolder;
      padding: 0.625rem 1rem;

      &.dropdown-toggle::after {
        display: none;
      }

      @include media-breakpoint-up(xl) {
        font-size: 1.3rem;
      }
    }

    // .dropdown-item {
    //   font-size: 0.938rem;
    //   font-weight: $font-weight-bold;
    // }


    @include break-up {
      flex-wrap: nowrap;

      .nav-link {
        white-space: nowrap;

        .sub-arrow {
          display: none;
        }
      }
    }

    @include media-breakpoint-up(xxl) {
      >.nav-item:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }
}