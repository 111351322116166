// 客製用
//================================================================================

// @include media-breakpoint-down(xs) {}
// @include media-breakpoint-down(sm) {}
// @include media-breakpoint-down(md) {}
// @include media-breakpoint-down(lg) {} XXXX以下
// @include media-breakpoint-between(md, xl) {}  特殊區間
// @include media-breakpoint-up(sm) { ... }   XXXX以上

//================================================================================

//================================================================================
/* 零件區 */
//================================================================================

/* btn */
.btn-more {
  color: darken($main-color01, 10%);
  font-family: $font-family-head;
  font-size: 1rem;
  font-weight: $font-weight-normal;
  letter-spacing: 1px;
  min-width: 12.5rem;
  padding: 0.75rem 1.25rem;
  border: solid 1px $main-color01;
  position: relative;
  background-color: transparent;
  touch-action: manipulation;

  &:after {
    content: "";
    background-color: rgba($main-color01, 0.1);
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
    top: 10px;
    left: 10px;
    transition: 0.2s;
  }

  &:hover:after {
    top: 0;
    left: 0;
  }
}

@media (min-width: 768px) {
  .button-52 {
    padding: 13px 50px 13px;
  }
}

//pagination
.pagination {
  @include break-down {
    flex-wrap: wrap;
  }
}
.page-item.active .page-link {
  font-weight: $font-weight-bold;
}
.page-link {
  font-family: $font-family-en;
  margin: 0.25rem;
}

.sec-title {
  color: $main-color02;
  font-size: 1.75rem;
  font-weight: $font-weight-bolder;
  line-height: 1;
  margin-bottom: 2rem;
  @include flex-center;
  &::before, &::after {
    content: "◆";
    font-size: 1rem;
    display: inline-block;
    padding: 0 1rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 2rem;
  }
}


.featherlight .featherlight-content {
  padding: 0;
}
//================================================================================
/* 列表區 */
//================================================================================

.list-item {
  width: 100%;
}

.block-list {
  li {
    margin-bottom: 1.5rem;
  }
  .item {
    &-img {
      width: 100%;
      padding-top: 133.33%;
      background-color: $white;
      @include background-img;
      border: solid 5px $white;
      box-shadow: $box-shadow;
      display: block;
      position: relative;
    }

    &-cover {
      color: $white;
      padding: 1rem;
      background: rgba($black, 0.5);
      @include flex-center;
      @include absolute-full;
      overflow: hidden;
    }

    &-content {
      padding: 1rem 0;
    }

    &-title {
      color: $text-color;
      font-size: 1rem;
      font-weight: $font-weight-bolder;
      @include text-ellipsis;
    }

    &-cate {
      color: $white;
      font-size: 0.875rem;
      font-family: $font-family-head;
      font-weight: $font-weight-bold;
      padding: 0.25rem 1rem;
      background-color: $main-color01;
      display: inline-block;
    }

    &-date {
      color: $gray-600;
      font-size: 0.875rem;
    }

    &-brief {
      color: $body-color;
      font-size: 1rem;
      max-height: 3rem;
      overflow: hidden;
    }
  }

  .list-item {
    @include hover-focus-active {
      .item {
        &-img {
          border-color: $main-color02;
          box-shadow: none;
        }
        &-title {
          color: $main-color03;
        }
        // &-brief {
        //   text-decoration: underline 1px dashed $gray-600;
        // }
      }
    }

    &.expired {
      .item-img::after {
        content: attr(data-expired);
        font-size: 2rem;
        font-family: $font-family-head;
        font-weight: $font-weight-bold;
        @extend .item-cover;
      }
    }
  }
}

.grid-list {
  .col {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .list-item {
    display: block;
  }
  .item {
    &-frame {
      width: 100%;
      padding: 0.5rem;
      background-color: transparent;
      border: solid 1px $white;
    }
    &-img {
      width: 100%;
      padding-top: 75%;
      @include background-img;
      display: block;
      position: relative;

      i {
        color: $white;
        position: absolute;
        right: 1rem;
        bottom: 1rem;
      }
    }

    &-content {
      color: $white;
      font-size: 1.5rem;
      font-family: $font-family-head;
      font-weight: $font-weight-bolder;
      text-align: center;
      text-shadow: 2px 2px 3px rgba($black, 0.5);
      padding: 1rem;
      @include flex-center;
      @include absolute-full;
      overflow: hidden;
      @include media-breakpoint-up(xl) {
        font-size: 2rem;
      }
    }
    &-title {
      color: $main-color01;
      font-size: 1.25rem;
      font-weight: $font-weight-bolder;
      @include media-breakpoint-up(xl) {
        font-size: 1.563rem;
      }
    }
  }
}

//================================================================================
/* 首頁區 */
//================================================================================

//首頁banner
.banner {
  // &-inner {}
  &-item {
    @extend .background-img;
    padding-top: 56.25%;
  }
  
}

.carousel-control-prev,
.carousel-control-next {
  background-color: rgba($black, 0);
  transition: all .2s ease-in;
  &:hover {
    background-color: rgba($black, 0.07);
  }
}

.indAbout {
  .lead {
    color: $text-color;
    font-family: $font-family-head;
  }
}

.indServe {
  background: url(../images/bg_serve.png) no-repeat top center;
  background-size: 100% 100%;
}

//================================================================================
/* 內頁區 */
//================================================================================

.pageBtn {
  .btn {
    margin: 0.25rem;
  }
}

.articles {
  @include media-breakpoint-up(xl) {
    font-size: 1.25rem;
    ol, ul {
      table {
        font-size: 1rem;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    font-size: 1.125rem;
    h3.h2 {
      font-size: 1.75rem;
    }
  }
  h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: $font-weight-bold;
  }
  p {
    margin-bottom: 0.625rem;
  }

  &-head {
    padding-bottom: 1rem;
    border-bottom: solid 1px $main-color01;
    @include flex-align-center;
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
    .date {
      color: $gray-700;
      font-family: $font-family-en;
      font-size: 0.938rem;
      font-weight: $font-weight-bold;
      white-space: nowrap;
    }
  }

  &-tag {
    color: $white;
    font-family: $font-family-head;
    font-size: 0.938rem;
    font-weight: $font-weight-bold;
    white-space: nowrap;
    line-height: 1.35;
    background-color: $main-color01;
    padding: 0.25rem 1.25rem;
  }

  &-title {
    color: $text-color;
    font-size: 1.5rem;
    margin: 0.5rem 0;
    @include media-breakpoint-up(xl) {
      font-size: 2rem;
      padding-right: 1rem;
    }
  }
}

.editors {
  color: $black;
  line-height: 1.8;
  img {
    @extend .img-fluid;
  }
  table {
    @extend .table;
  }
  table:not([class]) {
    border: solid 1px $table-border-color;
  }
  @include break-down {
    overflow-x: auto;
  }
}

.intro {
  color: $text-color;
  font-family: $font-family-head;
  font-size: 1.125rem;
}

.qna {
  background-color: $white;
  box-shadow: $box-shadow-sm;

  &+& {
    margin-top: 2.5rem;
  }

  &:hover,
  &:focus {
    border-color: $main-color02;
  }

  .card-body {
    padding-top: 0;
  }

  &-head {
    color: $gray-900;
    font-family: $font-family-head;
    font-size: 1.25rem;
    font-weight: $font-weight-bold;
    letter-spacing: 0.125rem;
    background-color: $white;
    border-bottom: none;
    @include flex-align-center;

    &:hover,
    &:focus {
      color: $main-color01;
    }

    i {
      color: $main-color03;
      font-size: 1.5rem;
      margin-left: auto;
    }
  }

  &-content {
    color: $text-color;
    font-size: 1.125rem;
    line-height: 1.8;
    padding: 1rem 0.625rem;
    background-color: #F0F8F5;

    p {
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.grid-list.films {
  .item {
    &-img {
      background-color: $white;
      border: solid 3px $white;
      box-shadow: $box-shadow;
    }

    &-title {
      color: $text-color;
      font-size: 1.125rem;
      text-align: center;
    }
  }

  .list-item {
    @include hover-focus {
      .item {
        &-img {
          box-shadow: none;
        }

        &-title {
          color: $main-color01;
        }
      }
    }
  }
}