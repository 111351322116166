// 引入用
// 修改的變數
@import "../scss/_variables-custom.scss";

// fontawesome icon
// @import "../scss/fontawesome-all";

// bootstrap 核心
@import "../../node_modules/bootstrap/scss/bootstrap.scss";

// 自訂文字
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@300;400;500;600;700;900&display=swap');

// featherlight
@import "_featherlight.scss";

// layout設定
@import "_layout.scss";

// navs設定
@import "_navs.scss";

// 客製化CSS
@import "_custom.scss";
